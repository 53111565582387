<template>
	<div class="content-body">
		<section class="card invoice-page">
			<div id="invoice-template" class="card-body">
				<!-- Invoice Company Details -->
				<div id="invoice-company-details" class="row">
					<div class="col-md-6 col-sm-12 text-left pt-1">
						<h5>Recipient</h5>
						<div class="recipient-info my-2">
							<p>{{ transactionDetail.customer ? transactionDetail.customer.name : '-' }}</p>
						</div>
						<div class="recipient-contact pb-2">
							<p>
								<i class="feather icon-mail"></i>
								{{ transactionDetail.customer ? transactionDetail.customer.email : '-' }}
							</p>
							<p>
								<i class="feather icon-phone"></i>
								{{ transactionDetail.customer ? transactionDetail.customer.phone : '-' }}
							</p>
						</div>
					</div>
					<div class="col-md-6 col-sm-12 text-right">
						<h1>Invoice</h1>
						<div class="invoice-details mt-2">
							<h6>INVOICE NO.</h6>
							<p>{{ transactionDetail.invoice_number }}</p>
							<h6 class="mt-2">INVOICE DATE</h6>
							<p>{{ transactionDetail.date }}</p>
							<h6 class="mt-2">Status</h6>
								<b-badge
									variant="light-success"
									v-if="transactionDetail.status == 'success'"
								>
									Sukses
								</b-badge>
								<b-badge
									variant="light-danger"
									v-if="transactionDetail.status == 'failed'"
								>
									Gagal
								</b-badge>
								<b-badge
									variant="light-warning"
									v-if="transactionDetail.status == 'pending'"
								>
									Pending
								</b-badge>
								<b-badge
									variant="light-secondary"
									v-if="transactionDetail.status == 'expired'"
								>
									Kadaluarsa
								</b-badge>
							<!-- <p>{{ transactionDetail.status }}</p> -->
						</div>
					</div>
				</div>
				<!--/ Invoice Company Details -->

				<!-- Invoice Recipient Details -->
				<div id="invoice-customer-details" class="row pt-2">
					
				</div>
				<!--/ Invoice Recipient Details -->

				<!-- Invoice Items Details -->
				<div id="invoice-items-details" class="pt-1 invoice-items-table">
					<div class="row">
						<div class="table-responsive col-sm-12">
							<table 
								class="table table-borderless"
								v-if="this.$route.params.link == 'services'"
							>
								<thead>
									<tr>
										<th>Service</th>
										<th>Price</th>
										<th>Qty</th>
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="item in transactionDetail.items"
										:key="item.uuid"
									>
										<td>{{ item.service.name }}</td>
										<td>{{ item.price }}</td>
										<td>{{ item.qty }}</td>
										<td>{{ item.total }}</td>
									</tr>
								</tbody>
							</table>
							<table 
								class="table table-borderless"
								v-else
							>
								<thead>
									<tr>
										<th>Paket</th>
										<th>Price</th>
										<th>Qty</th>
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{ transactionDetail.item ? transactionDetail.item.name : '-'  }}</td>
										<td>{{ transactionDetail.payment ? transactionDetail.payment.total : '-'  }}</td>
										<td>1</td>
										<td>{{ transactionDetail.payment ? transactionDetail.payment.subtotal : '-' }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div id="invoice-total-details" class="invoice-total-table">
					<div class="row">
						<div class="col-7 offset-5">
							<div class="table-responsive">
								<table class="table table-borderless">
									<tbody>
										<tr>
											<th>SUBTOTAL</th>
											<td>{{ transactionDetail.payment ? transactionDetail.payment.subtotal : '-' }}</td>
										</tr>
										<tr>
											<th>DISCOUNT</th>
											<td>{{ transactionDetail.payment ? transactionDetail.payment.discount : '-' }}</td>
										</tr>
										<tr>
											<th>TOTAL</th>
											<td>{{ transactionDetail.payment ? transactionDetail.payment.total : '-' }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<!-- Invoice Footer -->
				<div id="invoice-footer" class="text-right pt-3">
					<p>Transfer the amounts to the business amount below. Please include invoice number on your check.
						<p class="bank-details mb-0">
							<span class="mr-4">Payment With: <img :src="transactionDetail.payment ? transactionDetail.payment.payment_icon : ''" alt=""></span>
							<span>Virtual Number: <strong>{{ transactionDetail.payment ? transactionDetail.payment.va_number : '-' }}</strong></span>
						</p>
				</div>
				<!--/ Invoice Footer -->

			</div>
		</section>

	</div>
</template>

<script>

import {
	BRow, 
	BCol, 
	BCard, 
	BCardBody, 
	BTableLite, 
	BCardText, 
	BButton, 
	BAlert, 
	BLink,
	BBadge,
	VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
	directives: {
		Ripple,
		'b-toggle': VBToggle,
	},
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BBadge,
		BLink,
	},

	data(){
		return{
			transactionDetail: {}
		}
	},

	mounted() {
		this.loadItem()
	},

	methods: {
		async loadItem() {
			const url = this.$route.params.link
			const number = this.$route.params.uuid
			await this.$http.get(`admin/transactions/${url}/` + number)
				.then(response => {
					this.transactionDetail = response.data.data
				})
		},
	}

}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style>
.invoice-page {
	padding : 2.2rem;
}
.invoice-page .recipient-contact i, .invoice-page .company-contact i {
	padding-right : 0.2rem;
	position : relative;
	top : 2px;
}
.invoice-page .recipient-info p, .invoice-page .company-info p, .invoice-page .company-contact p, .invoice-page .recipient-contact p {
	line-height : 2;
	margin-bottom : 0;
}
.invoice-page .invoice-items-table table, .invoice-page .invoice-total-table table {
	border : 2px solid #F8F8F8;
}
.invoice-page{padding:2.2rem}.invoice-page .company-contact i,.invoice-page .recipient-contact i{padding-right:.2rem;position:relative;top:2px}.invoice-page .company-contact p,.invoice-page .company-info p,.invoice-page .recipient-contact p,.invoice-page .recipient-info p{line-height:2;margin-bottom:0}.invoice-page .invoice-items-table table,.invoice-page .invoice-total-table table{border:2px solid #F8F8F8}
</style>
